import { useEffect, useState } from 'react';
import cls from './Dashboard.module.css'
import { parseDateString } from '../../../helpers/parseDateString';
export const Dashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sorted, setSorted] = useState([])
    const fetchData = async (page) => {
        setLoading(true);
        const response = await fetch(`https://platform.smrtstats.com:8888/api/v1/stats/queue/?page=${page}`, );
        const json = await response.json();
        if(json.next) {
            fetchData(page + 1)
        } else {
            // setData((prev) => [...prev, ...json.results])
        }
        setData((prev) => [...prev, ...json.results])
        setLoading(false)
    }

    useEffect(() => {
        fetchData(1)
    },[])
    useEffect(() => {
        if(!loading){
            const tmp = sortObjectsByQueueStatus(data);
            setSorted(tmp)
        }
    }, [data])
    const styles = {
        'recount complete': {background: 'green', color: 'white'},
        'recount in progress': {background: 'yellow', color: 'black'},
        'queue for recount': {background: 'white', color: 'black'}
    }

    function sortObjectsByQueueStatus(objects) {
        const statusOrder = {
          'recount complete': 0,
          'recount in progress': 1,
          'queue for recount': 2,
        };
        return objects.sort((a, b) => {
          const statusA = a.queue_full_status.toLowerCase();
          const statusB = b.queue_full_status.toLowerCase();
      
          return statusOrder[statusA] - statusOrder[statusB];
        });
      }

    return (
        <div className="page">
            <header className={cls.header}>
                <div>id</div>
                <div>match_id</div>
                <div className={cls.teams}>
                    <div>team 1</div>
                    -
                    <div>team 2</div>
                </div>
                <div>compition</div>
                <div>status</div>
                <div>add_to_queue</div>
                <div>start_recount</div>
                <div>complete</div>
            </header>
            <main>
                {!loading && <ul className={cls.list}>
                    {sorted.map((item, index) => {
                        return <li style={styles[item.queue_full_status]} className={cls.item}>
                            <div>{index + 1}</div>
                            <div>{item.id}</div>
                            <div className={cls.teams}>
                                <div>{item.team_1}</div>
                                -
                                <div>{item.team_2}</div>
                            </div>
                            <div>{item.competition}</div>
                            <div>{item.queue_full_status}</div>
                            <div>{parseDateString(item.add_to_queue)}</div>
                            <div>{parseDateString(item.start_recount)}</div>
                            <div>{parseDateString(item.complete)}</div>
                            {item.queue_full_status === 'recount complete' && <div className={cls.recalc}>recalc</div>}
                        </li>
                    })}
                </ul>}
            </main>
        </div>
    )
}