import { createContext, useContext, useEffect, useState } from "react";
import { Header } from "../components/Header/Header";
import { Menu } from "../components/Menu/Menu";
import { RenderRoutes } from "../components/RenderRoutes/RenderRouter";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {
    const [user, setUser] = useState({ name: '', isAuthenticated: false });
    const login = (userName, password) => {
        return new Promise((resolve, reject) => {
            if (password === 'password') {
                setUser({ name: userName, isAuthenticated: true })
                resolve('success')
                localStorage.setItem('login', JSON.stringify({ name: userName, isAuthenticated: true }))
            } else {
                reject('Incorect password')
            }
        })
    }
    const logout = () => {
        setUser({ ...user, isAuthenticated: false })
        localStorage.removeItem(login)
    }
    useEffect(() =>{
        if(localStorage.getItem('login')){
            const user = JSON.parse(localStorage.getItem('login'))
            setUser({ name: user.userName, isAuthenticated: true })
        }
    }, [])
    return <AuthContext.Provider value={{ user, login, logout }}>
        <>
            <Header />
            <Menu />
            <RenderRoutes />
        </>
    </AuthContext.Provider>
}