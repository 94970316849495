export function parseDateString(dateString) {
    if(!dateString) return '-'
   // Создаем новый объект Date из строки
  const parsedDateTime = new Date(dateString);

  // Используем методы объекта Date для получения года, месяца, дня, часа и минут
  const year = parsedDateTime.getFullYear();
  // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1 к месяцу
  const month = (parsedDateTime.getMonth() + 1).toString().padStart(2, '0');
  const day = parsedDateTime.getDate().toString().padStart(2, '0');
  const hours = parsedDateTime.getHours().toString().padStart(2, '0');
  const minutes = parsedDateTime.getMinutes().toString().padStart(2, '0');

  // Собираем строку в формате "YYYY-MM-DD HH:MM"
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

  return formattedDateTime;
  }